import validate from "/application/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorization_45global from "/application/middleware/authorization.global.ts";
import fetch_45applications_45global from "/application/middleware/fetchApplications.global.ts";
import manifest_45route_45rule from "/application/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorization_45global,
  fetch_45applications_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}