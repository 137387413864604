import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as logouti6fihoMoQpMeta } from "/application/pages/logout.vue?macro=true";
import { default as indexBEA0KBSRzOMeta } from "/application/pages/management/applications/[applicationId]/[domainKey]/index.vue?macro=true";
import { default as indexGR7VAFmXn8Meta } from "/application/pages/management/applications/[applicationId]/index.vue?macro=true";
import { default as indexrWdcwKEV6pMeta } from "/application/pages/management/applications/index.vue?macro=true";
import { default as usersN9IZ3TW9xtMeta } from "/application/pages/management/users.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexUEHw9xstOJMeta || {},
    component: () => import("/application/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginFLbOmRceC3Meta || {},
    component: () => import("/application/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logouti6fihoMoQpMeta || {},
    component: () => import("/application/pages/logout.vue")
  },
  {
    name: "management-applications-applicationId-domainKey",
    path: "/management/applications/:applicationId()/:domainKey()",
    meta: indexBEA0KBSRzOMeta || {},
    component: () => import("/application/pages/management/applications/[applicationId]/[domainKey]/index.vue")
  },
  {
    name: "management-applications-applicationId",
    path: "/management/applications/:applicationId()",
    meta: indexGR7VAFmXn8Meta || {},
    component: () => import("/application/pages/management/applications/[applicationId]/index.vue")
  },
  {
    name: "management-applications",
    path: "/management/applications",
    meta: indexrWdcwKEV6pMeta || {},
    component: () => import("/application/pages/management/applications/index.vue")
  },
  {
    name: "management-users",
    path: "/management/users",
    meta: usersN9IZ3TW9xtMeta || {},
    component: () => import("/application/pages/management/users.vue")
  }
]