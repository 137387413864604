import {defineStore} from "pinia";
import type {
    ApplicationSpace,
    Application, ApplicationSpaceWithSettingsJson,
} from "@/types/application-management";

type State = {
    applications: Record<string, Application>;
    applicationSpaces: Record<string, ApplicationSpaceWithSettingsJson>;
};

export const useApplicationManagementStore = defineStore(
    "applicationManagementStore",
    {
        state: (): State => ({
            applications: {},
            applicationSpaces: {},
        }),
        getters: {
            getAllApplications(): Application[] {
                return Object.values(this.applications);
            },
            getApplicationsById(): Record<string, Application> {
                return Object.fromEntries(
                    Object.values(this.applications).map((app) => {
                        return [app.id, app];
                    })
                );
            },
            getApplicationById() {
                return (appId: string) => {
                    return this.getApplicationsById[appId];
                };
            },
            getApplicationByKey() {
                return (appKey: string) => {
                    return this.getAllApplications.find(app => app.key === appKey)
                };
            },
            getApplicationSpacesByApplicationId() {
                return (appId: string) => {
                    const app = this.getApplicationById(appId)
                    return Object.values(this.applicationSpaces).filter(
                        (applicationSpace) => {
                            return applicationSpace.applicationKey === app.key;
                        }
                    );
                };
            },
            getApplicationSpaceByKey() {
                return (spaceKey: string) => {
                    return Object.values(this.applicationSpaces).find(
                        (applicationSpace) => applicationSpace.key === spaceKey
                    );
                };
            },
            getApplicationSpaceById() {
                return (id: string) => this.applicationSpaces[id];
            },
        },
        actions: {
            saveApplicationSpace(applicationSpace: ApplicationSpaceWithSettingsJson) {
                // TODO: prevent override of settings / settingsJson which are not included in types
                return $fetch("/api/management/application-spaces", {
                    method: "POST",
                    body: {applicationSpace},
                }).then((data) => {
                    if (!data) {
                        console.error("Response error!")
                        return;
                    }
                    const applicationSpace = data.applicationSpace;
                    this.applicationSpaces[applicationSpace.id] = applicationSpace;
                });
            },
            saveApplication(application: Application) {
                return $fetch("/api/management/applications", {
                    method: "POST",
                    body: {application},
                }).then((data) => {
                    if (!data) {
                        console.error("Response error!")
                        return;
                    }
                    const application = data.application;
                    this.applications[application.id] = application;
                });
            },
            fetchApplications() {
                return $fetch("/api/management/applications").then((data) => {
                    data.applications.forEach(
                        (application) => (this.applications[application.id] = application)
                    );
                });
            },
            fetchApplicationSpaces() {
                return $fetch("/api/management/application-spaces").then((data) => {
                    data.applicationSpaces.forEach(
                        (applicationSpace) =>
                            (this.applicationSpaces[applicationSpace.id] = applicationSpace as ApplicationSpaceWithSettingsJson)
                    );
                });
            },
            deleteApplication(application: Application) {
                return $fetch(`/api/management/applications/${application.id}`, {
                    method: "DELETE"
                }).then(() => {
                    delete this.applications[application.id!]
                });
            },
            deleteApplicationSpace(applicationSpace: ApplicationSpace) {
                return $fetch(`/api/management/application-spaces/${applicationSpace.id}`, {
                    method: "DELETE"
                }).then(() => {
                    delete this.applicationSpaces[applicationSpace.id!]
                });
            },
        },
    }
);
