<template>
    <NuxtLayout>
        <h1>Die angeforderte Seite konnte nicht gefunden werden.</h1>
        <p>Vergewissere dich, dass diese Seite konfiguriert wurde, insbesondere, wenn es sich um eine Applikation
            handelt.</p>
    </NuxtLayout>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
