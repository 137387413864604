import {useAuthorizationStore} from "@/stores/authorization-store";
import type {Role} from "@/types/roles";

export default defineNuxtRouteMiddleware(async (to) => {
    const authorizedRoles = to.meta.authorizedRoles as Role[];
    // TODO: maybe throw error on missign authorizedRoles on page
    if (authorizedRoles == null) return abortNavigation();
    if (authorizedRoles.includes("UNAUTHORIZED")) return;

    const authStore = useAuthorizationStore();

    try {
        await $fetch("/auth/check");
    } catch {
        authStore.refreshToken();
    }

    const hasAuthorizedRole = authorizedRoles.some((role) => {
        return authStore.getUserRoles[role] === true;
    });
    if (hasAuthorizedRole) return;

    return navigateTo({name: "login", query: {target: to.fullPath}});
});
